.perspective {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  width: 100%;
  max-width: 320px;
  height: 192px;
  font-family: "Inter var", sans-serif;
}

.card-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 20px;
  color: white;
  font-size: 1.2rem;
  position: relative; 
}

.card-face-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 6px;
  overflow: hidden;
  z-index: 1;
}

.card-face-inner::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
}

.card-face-inner > * {
  position: relative;
  z-index: 2;
}

.front .card-face-inner {
  background-image: url('/public/images/card_front.jpeg');
}

.back .card-face-inner {
  background-image: url('/public/images/card_back.jpeg');
  transform: rotateY(180deg);
}

.mirror{
  margin: 0 auto;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  float: right;
}

.front {
  background-image: url('/public/images/card_front.jpeg');
}

.back {
  background-image: url('/public/images/card_back.jpeg');
  transform: rotateY(180deg) translateY(-100%);
}

