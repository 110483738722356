.shine-effect {
  position: relative;
  overflow: hidden;
}

.shine-effect::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: all 0.6s ease-in-out;
  transform: skewX(-20deg);
}

.group:hover .shine-effect::before {
  left: 150%;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.sliding-text {
  position: relative;
  overflow: hidden;
  height: 1.5em; 
}

.sliding-text p {
  position: absolute;
  width: 100%;
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}

.sliding-text .active {
  animation: slideUp 0.6s forwards;
}

.sliding-text .inactive {
  animation: slideOut 0.6s forwards;
}