.discipline-grid-avaliations {
  display: grid;
  grid-template-columns: 3fr 0.5fr 0.5fr 0.7fr;
  gap: 1rem;
}

@media (max-width: 768px) {
  .discipline-grid-avaliations {
    grid-template-columns: 0.7fr 0.4fr 0.8fr;
  }
}
