.tcc-grade-table {
  @apply flex flex-col gap-y-5
}


.tcc-grade-table th {
  @apply py-4 text-sm font-bold;
  background-color: var(--backgroundThree);
}

.tcc-grade-table td {
  @apply py-5 text-sm ;
}

.grid-table-tcc {
  grid-template-columns: 1fr 0.5fr 0.5fr;
  gap: 5px;
  padding: 0 20px
}

