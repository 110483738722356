.slide-from-top {
  animation: slideFromTop 0.2s ease-out;
}

@keyframes slideFromTop {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.navigation-transparence {
    background: var(--barColor50)
}