:root {
  --themeColor: rgb(19, 142, 184);
  --backgroundOne: rgb(12, 55, 73);
  --backgroundOne80: rgba(12, 55, 73, 0.8);
  --backgroundOne60: rgba(12, 55, 73, 0.6);
  --backgroundOne40: rgba(12, 55, 73, 0.4);
  --backgroundOne72: rgba(12, 55, 73, 0.72);
  --backgroundTwo: rgb(36, 92, 115);
  --backgroundThree: rgb(25, 73, 94);
  --textPrimary: rgb(255, 255, 255);
  --textSecondary: rgb(255, 255, 255);
  --iconsAndText: rgb(255, 255, 255);
  --iconAndTextReproved: rgb(0, 0, 0);
  --stepReproved: rgb(255, 215, 0);
  --barColor: rgb(12, 55, 73);
  --barColor85: rgba(12, 55, 73, 0.85);
  --barColor50: rgba(12, 55, 73, 0.5);
  --iconAndText: rgb(255, 255, 255);
  --hoverIconAndText: rgb(223, 224, 14);
  --buttonPrimary: rgb(223, 224, 14);
  --buttonSecondary: rgb(148, 163, 184);
  --buttonTertiary: rgb(255, 255, 255);
  --inputIcon: rgb(255, 255, 255);
  --alertBackground: rgb(40, 108, 136);
  --hoverButtonHome: rgb(12, 55, 73);
  --stepsIconAndText: rgb(255, 255, 255);
  --stepsIconAndTextReproved: rgb(0, 0, 0);
  --stepReproved: rgb(255, 215, 0);
}