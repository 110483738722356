.preview-grade-table {
  @apply flex flex-col gap-y-5 text-black
}

.preview-grade-table table {
  @apply text-center w-full ;
}

.preview-grade-table th {
  @apply px-1 py-[1px] text-left text-[39.5%] border-[1px] leading-[150%] border-white;
}

.preview-grade-table td {
  @apply px-1 py-[1px] text-left text-[39.5%] border-[1px] leading-[150%] border-white;
}

.table-text-center {
    text-align: center !important;
}

.preview-grade-table table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}

/* --------------------- */

.preview-grade-table-celso-lisboa {
  @apply flex flex-col text-black;
}

.preview-grade-table-celso-lisboa table {
  @apply text-center w-full;
  border-collapse: collapse;
  border-spacing: 0;
}

.preview-grade-table-celso-lisboa th,
.preview-grade-table-celso-lisboa td {
  @apply p-[2px] text-left text-[37%] leading-[150%] border-t-0 border-b-0 border-x border-black;
}

.disable-left-border {
  border-left-width: 0px !important;
}
.disable-right-border {
  border-right-width: 0px !important;
}

.preview-grade-table-celso-lisboa td {
 line-height: 100% !important;
}

.preview-grade-table-celso-lisboa thead th {
  @apply border-b;
}

.preview-grade-table-celso-lisboa tbody tr:first-child td, .preview-grade-table-celso-lisboa th {
  @apply border-t;
}

.preview-grade-table-celso-lisboa tbody tr:last-child td {
  @apply border-b;
}

/* --------------------- */

.certificate-grade-table {
  @apply flex flex-col gap-y-5
}

.certificate-grade-table table {
  @apply text-center w-full;
}

.certificate-grade-table th {
  @apply py-4 px-[1rem] text-sm font-bold;
  background-color: var(--backgroundTwo); 
}

.certificate-grade-table td {
  @apply py-5 px-[1rem] text-sm;
}

.certificate-grade-table table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}