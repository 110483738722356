.tj1 {
    @apply h-[150vh] from-gray-800 to-blue-600 bg-gradient-to-br w-full
}
.tj2 {
    @apply flex flex-col h-full gap-5 items-center justify-center
}
.tj3 {
    @apply font-mono hover:text-yellow-300 select-none text-center;
    color: var(--textPrimary);
}
.tj4 {
    @apply rounded-full duration-300 transition-all select-none w-[740px] h-[590px] cursor-pointer hover:scale-105
}