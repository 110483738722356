.bar {
    margin-right: 20px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.bar .middle {
    height: 2px;
    width: 20px;
    background: #fff;
    border-radius: 50px;
    position: absolute;
    transition: 0.3s ease;
    z-index: 999
}

.bar .top {
    bottom: 5px;
    height: 2px;
    width: 20px;
    background: #fff;
    border-radius: 50px;
    position: absolute;
    transition: 0.3s ease;
    z-index: 999
} 

.bar .bottom {
    top: 7px;
    height: 2px;
    width: 20px;
    background: #fff;
    border-radius: 50px;
    position: absolute;
    transition: 0.3s ease;
    z-index: 999
}

.bar.checked .top {
    transform: rotate(-45deg);
    width: 25px;
    transform-origin: right;
}

.bar.checked .bottom {
    transform: rotate(45deg);
    width: 25px;
    transform-origin: right;
    top: 11px
}

.bar.checked .middle {
    width: 0;
    opacity: 0;
    transform: translateX(50%)
}

/* .bar.checked .middle {
    transform: translateX(20px);
    opacity: 0;
} */