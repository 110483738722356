body {
  background-color: black;
}


input,
button, textarea,select,option {
  outline: none;
}

/* Input CSS - Start */
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  accent-color: var(--themeColor);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--backgroundTwo) inset !important;
  caret-color: var(--textPrimary);
}
input:-webkit-autofill {
  -webkit-text-fill-color: var(--textPrimary) !important;
}

.check-container {
  display: flex;
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: var(--backgroundTwo);
  border-radius: 0.25rem;
  transition: all 0.3s;
}
.check-container:hover input ~ .checkmark {
  background-color: #4e4e55;
}
.check-container input:checked ~ .checkmark {
  background-color: var(--themeColor);
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.check-container .checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.check-container input:checked ~ .checkmark:after {
  display: block;
}
/* Input CSS - END */

/* Scroll Settings - Start */
body::-webkit-scrollbar-thumb, .custom-dark-scroll::-webkit-scrollbar-thumb {
  background-color: var(--themeColor);
  border-radius: 1.25rem;
}

body::-webkit-scrollbar {
  width: 7px !important;
}

.custom-dark-scroll::-webkit-scrollbar {
  width: 4px !important;
}

body::-webkit-scrollbar-track, .custom-dark-scroll::-webkit-scrollbar-track {
  background: var(--backgroundOne);
}

@media (max-width: 1024px) {
  body::-webkit-scrollbar, .custom-dark-scroll::-webkit-scrollbar {
    width: 0 !important; 
  }
}

/* Scroll Settings - End */

.custom-transparent-scroll-background::-webkit-scrollbar-track {
  background: transparent !important;
}


.prose-sm :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.1428571em;
  margin-bottom: 1.1428571em;
}

.prose-sm :where(h2):not(:where([class~="not-prose"] *)) {
  font-size: 1.4285714em;
  margin-top: 1.6em;
  margin-bottom: 0.8em;
  line-height: 1.4;
  font-weight: 700;
}

.prose-sm :where(ul):not(:where([class~="not-prose"] *)) {
  padding-left: 1.5714286em;
  list-style-type: none;
  margin-bottom: 1.1428571em;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(47%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(93%) contrast(90%);
}

.important-opacity-0 {
  opacity: 0 !important;
}